<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">
          <h1 class="text-h4">{{ $lang.routes.libraries }}</h1>
          <div class="d-inline-flex align-center">
            <v-switch v-model="activeOnly" data-cy="switch-active-only" :label="$lang.labels.activeOnly" class="pl-5 pr-3"></v-switch>
            <v-text-field
              v-model="search"
              v-debounce:500="handleDebounceSearch"
              hide-details
              outlined
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <v-select
              v-model="permissionType"
              class="pl-2"
              hide-details
              :items="typesFormatted"
              :label="$lang.labels.permissionType"
              outlined
              dense
              clearable
            ></v-select>
            <div>
              <v-btn
                text
                light
                class="ml-2"
                color="primary"
                @click="$emit('fetchLibraries', { options: options, search: search && search.length > 1 ? search : '', permissionType: permissionType, resetPage: true })"
              ><v-icon
                dense
                small
              >mdi-refresh</v-icon></v-btn>
            </div>
            <v-btn
              v-if="roles.includes('LIBRARY_CREATOR')"
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'libraryCreate' }"
            >{{ $lang.actions.create }}
            </v-btn>
          </div>
        </div>
        <v-divider class="mt-3"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="secondary" class="mx-1 button-default-width" @click="$emit('changeStatus', {id: item.id, status: item.status})">{{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}</v-btn>
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: 'libraryEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
          <v-btn
            v-if="roles.includes('PLUGIN_CREATOR')"
            color="accent"
            class="ml-1 button-default-width color-accent-text"
            @click="$emit('copyCreate', item)"
          >{{ $lang.actions.copyCreate }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      activeOnly: true,
      search: '',
      permissionType: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$lang.labels.type,
          align: 'start',
          sortable: true,
          value: 'type'
        },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      permissionTypes: ['EDIT', 'USE', 'VIEW']
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    typesFormatted () {
      return this.permissionTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchLibraries', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType })
        this.savePreFill()
      },
      deep: true
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    },
    permissionType: {
      handler () {
        if (!this.lock) this.$emit('fetchLibraries', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    },
    search: {
      handler (val) {
        if (!this.lock && (val === null || val === '')) this.$emit('fetchLibraries', { options: this.options, search: '', permissionType: this.permissionType, resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchLibraries', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.libraryList) {
        this.search = preFill.libraryList.search
        this.activeOnly = preFill.libraryList.activeOnly
        this.permissionType = preFill.libraryList.permissionType
        this.options = preFill.libraryList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true } })
      } else {
        this.$emit('fetchLibraries', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
      }
    } else {
      this.$emit('fetchLibraries', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    handleDebounceSearch(val, event) {
      this.search = val
      if (!this.lock && val && val.length > 1) this.$emit('fetchLibraries', { options: this.options, search: val, permissionType: this.permissionType, resetPage: true })
      if (!this.lock && (val === null || val === '')) return
      this.savePreFill()
      this.options.page = 1
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.libraryList) preFill.libraryList = {
          search: '',
          activeOnly: true,
          permissionType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.libraryList.search = this.search
        preFill.libraryList.activeOnly = this.activeOnly
        preFill.libraryList.permissionType = this.permissionType
        preFill.libraryList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
